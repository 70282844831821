export function initPostMessageListeners() {
  window.addEventListener('message', (event) => {
    if (event && event.data && typeof event.data === 'object' && event.data.message != "" && 'hasCLP' in event.data) {
      if (event.data.hasCLP) {
        document.body.classList.add('user-has-clp');   
      } else {
        document.body.classList.remove('user-has-clp');
      }
    } else if (event && event.data && typeof event.data === 'object' && event.data.message != "" && 'isCustomer' in event.data) {
      if (event.data.isCustomer) {
        document.body.classList.add('user-is-customer');   
      } else {
        document.body.classList.remove('user-is-customer');
      }
    } else if (event && event.data && typeof event.data === 'object' && event.data.message != "" && 'isInternalUser' in event.data) {
      const currentSearchUrl = window.location.search;
      const currentParams = new URLSearchParams(currentSearchUrl);
      const currentReferrer = currentParams.get('referrerPageUrl');
      const isPartnerComm = currentReferrer && (currentReferrer.includes('partnercommunity.conga.com') || currentReferrer.includes('apttus2--c2uat.sandbox.my.site.com/C2'));
      if (event.data.isInternalUser && isPartnerComm) {
        document.body.classList.add('user-is-internal');   
      } else {
        document.body.classList.remove('user-is-internal');
      }
    }
  }, false);
}


export function removeInternalReferences() {
  //Remove internal refs from 'No results' page
  const alternativeVertEls = document.querySelectorAll('.yxt-AlternativeVerticals-suggestionLink--copyLabel');
  const isInternalUser = document.body.classList.contains('user-is-internal');
  if (!isInternalUser && alternativeVertEls) {
    for (let i = 0; i<alternativeVertEls.length; i++){
      let el = alternativeVertEls[i];
      if (el.innerHTML.includes("Internal KB Articles")){
        el.parentNode.parentNode.remove();
      }
    }
  }
}
